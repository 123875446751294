.root {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.description {
  display: flex;
  flex-direction: column;
}

.lang {
  display: flex;
}

.select {
  min-width: 60px;
}
