.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.question {
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
